.mediaSlider{
  > h2,
  > p{
    &:empty{
      display: none;
    }
  }
}

.sn-media-slider {

  .slider-pagination {

    .paging-item {
      background-color: #FFF;
      width: 20px;
      height: 20px;
      padding: 4px;
      box-sizing: content-box;
      background-clip: content-box !important;

      &.flex-active,
      &:hover{
        background-color: $secondary-color;
      }

    }

  }

  .slider-pagination{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  .slider {

    &.bottom-left-paging,
    &.bottom-center-paging,
    &.bottom-right-paging{
      margin-bottom: 0;
    }

  }

}

.custom-media-slider{

  .sn-media-slider .slider {

    .slide-title {
      margin: 3% 0 0;
      text-transform: uppercase;
    }

    .slide-description {
      margin: 0;
      font-weight: 400;
      text-shadow: 1px 1px 2px rgba(0,0,0,.5);
    }

    .slide .slide-overlay {
      padding: 1% 15%;
    }

    .slide-text {

      &.bottom-left,
      &.bottom-right,
      &.bottom-center { padding-bottom: 10%; }

    }

  }

  .slide-link-custom {
    position: absolute;
    width: 100%;
    bottom: 10%;
    left: 0;
    padding: 0 15%;
  }

  .media-slider-button{
    @include link-element;
    margin-top: 20px;
    display: inline-block;
    font-size: 16px;

    @media screen and (max-width: 768px){
      font-size: 12px;
    }

  }

}
